import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import SubTitle from "../components/subtitle";
import styled from "styled-components";
import PhotoImage from "../components/photo-image";
import { classNames } from "../util/Util";

const images: {
  caption: string;
  src: string;
  description?: string;
}[] = [
  {
    caption: "待合室",
    src: require("../images/photos/introduction/026.jpg"),
    description: "キッズスペース・自動販売機・本棚を完備しております",
  },
  {
    caption: "処置スペース",
    src: require("../images/photos/introduction/shochi_space.jpg"),
  },
  {
    caption: "お手洗い",
    src: require("../images/photos/introduction/toilet.jpg"),
    description: "おむつ交換台・ベビーキャッチャーも完備しております",
  },
];

const BIG_WIDTH = 800;

const Intro = styled.div`
  padding: 16px;
`;

const PhotoLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 40px;
  .photo {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
    padding: 24px 16px;
  }
  .photo__big {
    flex-basis: ${BIG_WIDTH}px;
    @media screen and (max-width: ${BIG_WIDTH}px) {
      flex-basis: 300px;
    }
  }
`;

const IntroductionPage = () => (
  <Layout>
    <Section>
      <SubTitle>院内風景のご紹介</SubTitle>
      <Intro>
        当院は令和元年9月2日に新築移転いたしました。
        <br />
        新しい施設でこれまで以上に地域医療へ貢献できるようにスタッフ一同努めて参ります。
      </Intro>
      <PhotoLayout>
        {images.map((image, index) => {
          return (
            <PhotoImage
              key={image.src + index}
              src={image.src}
              caption={image.caption}
              wrapperClassName={classNames({
                photo__big: index % 3 === 0,
                photo: true,
              })}
              description={image.description}
            />
          );
        })}
      </PhotoLayout>
    </Section>
    <Section>
      <SubTitle>院内の360°ビュー</SubTitle>
      <p>待合室や処置室の様子を見ることができます。</p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!4v1579511582260!6m8!1m7!1sCAoSLEFGMVFpcE5VVXVLV01TYWN0NXBKWnI1b3BpNDltN0FtQ1c4SVcyZjlCOGdD!2m2!1d33.216540519492!2d130.55851665293!3f169.043982591284!4f-8.487828601583075!5f0.7820865974627469"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen={false}
      />
    </Section>
  </Layout>
);

export default () => <IntroductionPage />;
